import { useState } from "react";

function App() {
  const rf = {current: undefined};
  const url = rf;
  const reqtype = {...rf};
  const reqHeader = {...rf};
  const reqBody = {...rf};
  const resHeader = {...rf};
  const resBody = {...rf};
  const [resError, setRe] = useState([{code: {current: undefined}, msg: {current: undefined}}]);
  const mdgen = {...rf};
  const ctc = {...rf};
  const [mdstring, setMd] = useState('');

  const generatemd = () => {
    mdgen.current.classList.remove('d-none');
    let mds = `# \`${url.current.value}\` **${reqtype.current.value}**\n\n## Request\n\n### Request Headers\n\n`;
    mds += '```json\n' + reqHeader.current.value;
    mds += '\n ```\n\n### Request Payload\n\n```json\n' + reqBody.current.value;
    mds += '\n```\n---\n## Response\n\n### Response Header\n\n';
    mds += '```json\n' + resHeader.current.value + '\n```\n\n### Response Body\n\n```json\n' + resBody.current.value + '\n```\n\n';
    resError.map(rs => {
      if (rs === undefined) {
        return null;
      }
      mds += '#### Response Error - `'+rs.code.current.value+'`\n\n```json\n' + rs.msg.current.value + '\n```\n\n';
      return <></>;
    });
    setMd(mds);
    mdgen.current.scrollIntoView({behavior: 'smooth'});
  };

  const putjson = (e) => { if (e.target.value === "") { e.target.value = "{\n\t\"\":\"\"\n}"; e.target.selectionEnd = 4; } };

  const deletethis = (inde) => {
    resError[inde].code.current.parentElement.parentElement.parentElement.classList.add('bg-warning');
    setTimeout(() => {
      if (window.confirm(`Are you sure you want to delete the error code: ${resError[inde].code.current.value} with the message: ${resError[inde].msg.current.value}`)) {
        let rz = [...resError];
      delete rz[inde];
      setRe(rz);
      } else {
        resError[inde].code.current.parentElement.parentElement.parentElement.classList.remove('bg-warning');
      }
    }, 1000);
    
  }

  const ctcf = () => {
    ctc.current.innerHTML = "Copied!";
    navigator.clipboard.writeText(mdstring);
    setTimeout(() => ctc.current.innerHTML = "Click to Copy", 4800);
  }

  return (
    <div className="container mt-5">
      <div className="row gy-3">
        <div className="col-12">
          <h1>API Documentation MD File Creator</h1>
          <hr />
          <h2>Request</h2>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
          <label htmlFor="url">Enter Endpoint</label>
          <input type="text" className="form-control" id="url" ref={url} placeholder="eg: /login" />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
          <label htmlFor="typeofreq">Type of Request</label>
          <select className="form-select" id="typeofreq" ref={reqtype}>
            <option>GET</option>
            <option>POST</option>
            <option>PUT</option>
            <option>DELETE</option>
          </select>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
          <label htmlFor="reqheader">Request Header</label>
            <textarea rows="5" onFocus={putjson} id="reqheader" ref={reqHeader} className="form-control" placeholder='eg: {"Content-Type":"application/json", "X-Access-Token":"SecretToken"}'></textarea>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
          <label htmlFor="reqbody">Request Body</label>
            <textarea rows="5" onFocus={putjson} id="reqbody" ref={reqBody} className="form-control" placeholder='eg: {"title":"Title of my blog post", "authorId":"99"}'></textarea>
          </div>
        </div>
      </div>
      <hr />
      <div className="row gy-3">
        <div className="col-12">
          <h2>Response</h2>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
          <label htmlFor="resheader">Response Header</label>
            <textarea rows="3" onFocus={putjson} id="resheader" ref={resHeader} className="form-control" placeholder='eg: {"Content-Type":"application/json", "X-Access-Token":"SecretToken"}'></textarea>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group">
          <label htmlFor="resbody">Response Body</label>
            <textarea rows="3" onFocus={putjson} id="resbody" ref={resBody} className="form-control" placeholder='eg: {"title":"Title of my blog post", "authorId":"99"}'></textarea>
          </div>
        </div>
        </div>
        <div className="row my-3">
        <div className="col-lg-3 col-6">
            <button className="btn btn-danger mt-4 btn-sm w-100" onClick={
              () => {
                setRe(prev => [...resError, {code: {...rf}, msg: {...rf}}]);
              }
            }>Add One more Error Response</button>
          </div>
          </div>
          {resError.map((re, i) => {
            if (re === undefined) {
              return null;
            }
            return <div key={i} className="row py-3 position-relative">
            <div className="col-lg-6 col-12">
            <div className="form-group">
            <span className="badge pointer bg-danger position-absolute end-0 top-50" onClick={() => {
              deletethis(i);
            }}>x</span>
            <label htmlFor={"reserrorcode"+i}>Response <span className="text-danger">Error Code</span></label>
              <input id={"reserrorcode"+i} ref={re.code} className="form-control" placeholder='eg: 404' type="number" />
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="form-group">
            <label htmlFor={"reserror"+i}>Response <span className="text-danger">Error Body</span></label>
              <input id={"reserror"+i} ref={re.msg} className="form-control" placeholder='eg: {"Error":"Unauthorized"}' />
            </div>
          </div></div>})}
        <hr />
        <div className="row my-3">
        <div className="col-12">
          <div className="mx-auto col-lg-2 col-12">
          <button className="btn btn-primary btn-lg" onClick={generatemd}>Generate MD</button>
          </div>
        </div>
      </div>
      <div className="row d-none" ref={mdgen}>
        <div className="text-end">
        <button className="btn-small btn-primary btn cursor-pointer"
        onClick={ctcf} ref={ctc}>Click to Copy</button>
        </div>
        <div className="col-12 card mb-5 p-3 wrapper form-textarea user-select-all" rows="15">
          {mdstring}
        </div>
      </div>
    </div>
  );
}

export default App;
